/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type StorageDto = {
    backupSize?: string;
    hostname?: string;
    password?: string;
    port?: string;
    size?: string;
    type?: StorageDto.type;
    user?: string;
};

export namespace StorageDto {

    export enum type {
        MEMORY = 'Memory',
        POSTGRES = 'Postgres',
        EXTERNAL = 'External',
    }


}


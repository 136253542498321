/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EnvVar } from './EnvVar';
import type { ResourcesDto } from './ResourcesDto';
// for FE types validation
import { StorageDto } from './StorageDto';

export type RemoteParticipantDto = {
    adminAddress: string;
    adminPort: string;
    authProvider: RemoteParticipantDto.authProvider;
    envVars?: Array<EnvVar>;
    image: string;
    jsonApiUrl: string;
    ledgerAddress: string;
    ledgerId: string;
    ledgerPort: string;
    name: string;
    phase?: RemoteParticipantDto.phase;
    resources: ResourcesDto;
    v3?: boolean;
    // for FE types validation
    storage?: StorageDto;
};

export namespace RemoteParticipantDto {

    export enum authProvider {
        KEYCLOAK = 'keycloak',
        AUTH0 = 'auth0',
    }

    export enum phase {
        COMPLETED = 'COMPLETED',
        PENDING = 'PENDING',
        RUNNING = 'RUNNING',
    }


}


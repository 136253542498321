export enum Labels {
  DOMAIN_NAME = 'Domain name',
  CANTON_IMAGE = 'Canton image',
  IMAGE_PULL_SECRET = 'Image pull secret',
  ENABLE_DAEMON_MODE = 'Enable Daemon mode',
  ENABLE_ENTERPRISE = 'Enable Enterprise',
  ENABLE_INGRESS = 'Enable Ingress',
  REQUESTED_CPU = 'Requested CPU',
  CPU_LIMIT = 'CPU limit',
  REQUESTED_MEMORY = 'Requested memory',
  MEMORY_LIMIT = 'Memory limit',
  REPLICAS = 'Replicas',
  TOPOLOGY = 'Topology',
  BOOTSTRAP = 'Bootstrap',
  ENV_VARS = 'Environment variables',
  SECRET_NAME = 'Secret name',
  SECRET_KEY = 'Secret key',
  ADMIN_PORT = 'Admin port',
  PUBLIC_PORT = 'Public port',
  LEDGER_PORT = 'Ledger port',
  LEDGER_ADDRESS = 'Ledger address',
  LEDGER_ID = 'Ledger ID',
  STORAGE_TYPE = 'Storage type',
  STORAGE_USER = 'User',
  STORAGE_HOSTNAME = 'Hostname',
  STORAGE_PORT = 'Port',
  STORAGE_SIZE = 'Size',
  STORAGE_BACKUP_SIZE = 'Backup size',
  ADMIN_ADDRESS = 'Admin address',
  PUBLIC_ADDRESS = 'Public address',
  LOG_LEVEL = 'Log level',
  NAME = 'Name',
  IMAGE_TAG = 'Image tag',
  IMAGE_REPO = 'Image repo',
  POSTGRES_USER = 'Postgres user',
  POSTGRES_PASSWORD = 'Postgres password',
  CLUSTER_URL = 'Cluster URL',
  ENABLE_WALLET = 'Enable wallet',
  FAIL_ON_APP_VERSION_MISMATCH = 'Fail on app version mismatch',
  USE_SEQUENCER_CONNECTIONS_FROM_SCAN = 'Use sequencer connections from scan',
  SCAN_ADDRESS = 'Scan address',
  SV_SPONSOR_ADDRESS = 'SV Sponsor Address',
  DEFAULT_JVM_OPTIONS = 'Default JVM Options',
  PARTY_HINT = 'Party hint',
  ATTACH_PVC = 'Attach PVC',
  MIGRATING = 'Migrating',
  TOP_UP_ENABLE = 'Top up enable',
  TOP_UP_MIN_INTERVAL = 'Min Top up interval',
  TOP_UP_TARGET_THROUGHPUT = 'Target throughput',
  DISABLE_AUTO_INIT = 'Disable auto init',
  ENABLE_HEALTH_PROBES = 'Enable health probes',
  NODE_IDENTIFIER = 'Node identifier',
  IDENTITIES_DUMP_IMPORT = 'Participant identities dump import',
  IDENTITIES_DUMP_BACKUP = 'Participant identities dump periodic backup',
  BACKUP_INTERVAL = 'Back up interval',
  LOCATION_BUCKET_NAME = 'Location bucket name',
  LOCATION_BUCKET_PROJECT_ID = 'Location bucket project id',
  LOCATION_BUCKET_SECRET_NAME = 'Location bucket secret name',
  LOCATION_PREFIX = 'Location prefix name',
  ONBOARD_SECRET = 'Onboard secret',
  PARTICIPANT_NODE = 'Participant node',
  VALIDATOR_BACKEND = 'Validator backend',
  CNS_UI = 'Canton Name Service UI',
  WALLET_UI = 'Wallet UI',
  TOP_UP = 'Top up',
  MIGRATION = 'Migration',
  MIGRATION_ID = 'Migration id',
  ID = 'Id',
  MAIN_SETTINGS = 'Main settings',
  ENV_VARIABLES = 'Environment variables',
  SUMMARY = 'Summary',
  CLUSTER_CONFIG = 'Cluster configuration',
  CLUSTER_PARTICIPANT_CONFIG = 'Cluster participant configuration',
  OPTIONAL = 'Optional',
  APPLICATION_NAME = 'Application name',
  APPLICATION_TYPE = 'Application type',
  UI = 'UI',
  BACKEND = 'Backend',
  FILE = 'File',
  PUBLIC_URL = 'Public url',
  SUBDOMAIN = 'Subdomain',
  CUSTOM_SUBDOMAIN = 'Custom subdomain',
  PARTICIPANT_SUBDOMAIN = 'Participant subdomain',
  PORT = 'Port',
  PARTICIPANT_NAME = 'Participant name',
  ENABLE_NAVIGATOR = 'Enable navigator',
  ENABLE_AUTH_SERVICE = 'Enable Authorization service',
  ENABLE_JSON_API_QUERY_STORE_DATABASE = 'JSON API Query Store Database ',
  AUTHENTICATION_PROVIDER = 'Authentication Provider',
  REMOTE_PARTICIPANT_NAME = 'Remote participant name',
  JSON_API_URL = 'JSON API URL',
  RESOURCES = 'Resources',
  MIGRATE_VALIDATOR_PARTY = 'Migrate Validator Party',
  IDENTITIES_SECRET_NAME = 'Secret name',
  NEW_PARTICIPANT_IDENTIFIER = 'New participant identifier',
  IMAGE = 'Image',
  MODE = 'Mode',
  TYPE = 'Type',
  NAVIGATOR = 'Navigator',
  AUTHORIZATION_SERVICE = 'Authorization service',
  APPLICATION = 'Application',
  APPLICATION_WALLET = 'Application wallet',
  APPLICATION_CNS = 'Application CNS',
  DOMAIN = 'Domain',
  PARTICIPANT = 'Participant',
  PACKAGE_NAME = 'Package name',
  VALIDATOR_WALLET_PASSWORD = 'Validator wallet user password',
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AuthorizationDto } from './AuthorizationDto';
import type { EnvVar } from './EnvVar';
import type { ResourcesDto } from './ResourcesDto';
import type { StorageDto } from './StorageDto';
import { LogLevel } from '../../constants';

export type ParticipantDto = {
    adminAddress?: string;
    adminPort: string;
    auth?: boolean;
    authProvider: ParticipantDto.authProvider;
    authorization?: AuthorizationDto;
    bootstrap?: string;
    daemon?: boolean;
    /**
     * Whether the canton image is enterprise or not
     */
    enterprise?: boolean;
    envVars?: Array<EnvVar>;
    image: string;
    jsonapi?: boolean;
    jsonapiImage?: string;
    jsonapiQueryStore?: boolean;
    ledgerAddress?: string;
    ledgerPort: string;
    logLevel?: LogLevel;
    name: string;
    navigator?: boolean;
    phase?: ParticipantDto.phase;
    resources: ResourcesDto;
    storage: StorageDto;
    topology?: string;
    validatorParent?: string;
};

export namespace ParticipantDto {

    export enum authProvider {
        KEYCLOAK = 'keycloak',
        AUTH0 = 'auth0',
    }

    export enum phase {
        COMPLETED = 'COMPLETED',
        PENDING = 'PENDING',
        RUNNING = 'RUNNING',
    }


}

